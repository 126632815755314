.Phonetic {
  margin: 20px 0 0;
}

.Phonetic h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
  display: inline;
}

.Phonetic a {
  color: #6c63ff;
  font-weight: normal;
  margin-right: 15px;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 18px;
  border: 1px solid #dddddd;
  border-radius: 50px;
  text-decoration: none;
}
