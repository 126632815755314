.Meaning h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 20px;
  color: black;
}

.Meaning p {
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin: 20px 0 10px;
}

.Meaning em {
  font-size: 13px;
  line-height: 15px;
  color: #666666;
}
