.Dictionary {
  max-width: 960px;
  margin: 0 auto;
}

.Dictionary section {
  box-shadow: 0px 30px 50px rgba(65, 50, 100, 0.08);
  border-radius: 8px;
  background: white;
  margin-top: 10px;
  padding: 40px;
}

.Dictionary label {
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  margin-bottom: 20px;
  display: block;
  line-height: 29px;
}

.Dictionary .search-input {
  display: block;
  height: 64px;
  color: #666666;
  font-size: 18px;
  line-height: 21px;
  padding: 22px;
  border: 1px solid #dddddd;
  border-radius: 6px;

  width: 100%;
}


.Dictionary .hint {
	margin-top: 15px;
	display: block;
}