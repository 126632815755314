.Synonyms {
  margin: 30px 0 0;
}

.Synonyms ul {
  padding: 0;
  margin: 0;
}

.Synonyms li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}
